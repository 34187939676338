import ThemisInput from "@/components/shared/input"
import ThemisWorkflowTransition from "@/components/workflow-transition"
import { TRANSITION_DIRECTIONS, MAX_CHARACTER_LIMIT, ISSUE_STATUS_CATEGORY } from "@/constants"
import { mapActions, mapGetters, mapMutations } from "vuex"
import ThemisDecision from "@/components/shared/decision"
import ThemisReadOnly from "@/components/shared/read-only"
import { saveToLocalStorage, getFromLocalStorage } from "@/utils"

export default {
  name      : "WorkflowStatusSidePanel",
  components: {
    ThemisInput,
    ThemisWorkflowTransition,
    ThemisDecision,
    ThemisReadOnly
  },
  data() {
    return {
      localWorkflowStatus           : null,
      workflows                     : getFromLocalStorage("workflows"),
      transitionDirections          : TRANSITION_DIRECTIONS,
      isStatusNameDuplicate         : false,
      statusNameCharacterLimit      : MAX_CHARACTER_LIMIT.STATUS_NAME,
      changedWorkflows              : getFromLocalStorage("changedWorkflows"),
      isStatusNameFocused           : false,
      showRemoveWorkflowStatusDialog: false
    }
  },
  computed: {
    ...mapGetters({
      workflowsFromStore  : "workflows/workflows",
      issueStatuses       : "issueStatuses/issueStatuses",
      issueTypes          : "issueTypes/issueTypes",
      workflowAssociations: "workflowAssociations/workflowAssociations"
    }),
    workflow() {
      return this.workflows.find(workflow => workflow.id === +this.$route.params.id)
    },
    isStatusUsedInOtherWorkflows() {
      return !!this.workflows.some(workflow => {
        return workflow.id !== this.workflow.id && workflow.statuses.some(status => {
          return status.name === this.workflowStatus?.name
        })
      })
    },
    statusesMap() {
      const statusesMap = {}
      for (const status of this.workflow.statuses) {
        if (status.id) {
          statusesMap[status.id] = status
        } else {
          statusesMap[status.name] = status
        }
      }
      return statusesMap
    },
    workflowStatus() {
      return this.workflow.statuses.find(status => status.name === this.selectedStatus)
    },
    selectedStatus() {
      return this.$route.query.status
    },
    isWorkflowStatusNameChanged() {
      return this.localWorkflowStatus?.name !== this.workflowStatus?.name
    },
    workflowFromStore() {
      return this.workflowsFromStore.find(workflow => workflow.id === this.workflow.id)
    },
    issueStatus() {
      return this.issueStatuses.find(status => status.id === this.workflowStatus?.id)
    },
    getIncomingTransitions() {
      const workflowTransitions = this.workflow.transitions.filter(transition => {
        return transition.transitionLinks.some(transitionLink => {
          return transitionLink.toStatusId === this.workflowStatus?.id ||
            transitionLink.toStatusId === this.workflowStatus?.name
        })
      })
      return this.getTransitionsToDisplay(workflowTransitions)
    },
    getOutgoingTransitions() {
      const workflowTransitions = this.workflow.transitions.filter(transition => {
        return !transition.initialTransition && transition.transitionLinks.some(transitionLink => {
          return ((
            typeof this.workflowStatus?.id !== "undefined" &&
            transitionLink.toStatusId !== this.workflowStatus?.id &&
            (
              transitionLink.fromStatusId === this.workflowStatus?.id ||
              transitionLink.fromStatusId === null
            )
          ) || (
            typeof this.workflowStatus?.id === "undefined" &&
              transitionLink.toStatusId !== this.workflowStatus?.name &&
              (
                transitionLink.fromStatusId === this.workflowStatus?.name ||
                transitionLink.fromStatusId === null
              )
          ))
        })
      })
      return this.getTransitionsToDisplay(workflowTransitions)
    },
    isStatusSystemDefault() {
      return this.localWorkflowStatus?.systemDefault
    },
    disableUpdateWorkflow() {
      return !this.localWorkflowStatus.name || this.isStatusNameDuplicate || !this.isWorkflowNameWithinCharacterLimit
    },
    isWorkflowNameWithinCharacterLimit() {
      return this.localWorkflowStatus.name?.length <= this.statusNameCharacterLimit
    },
    disableRemoveWorkflowStatusButton() {
      return this.isWorkflowAssociated || this.statusHasInitialTransition || this.isStatusCategoryDoneAndLastStatus
    },
    isWorkflowAssociated() {
      return !!this.workflowAssociations.find(workflowAssociation =>
        workflowAssociation.workflowId === this.workflow.id
      )
    },
    statusHasInitialTransition() {
      return !!this.workflow.transitions.find(transition => {
        return transition.initialTransition && transition.transitionLinks.find(transitionLink => {
          return transitionLink.toStatusId === this.workflowStatus?.id && transitionLink.fromStatusId === null
        })
      })
    },
    isSingleStatusInDoneCategory() {
      const statuesWithDoneCategory = this.workflow.statuses.filter(status =>
        status.category === ISSUE_STATUS_CATEGORY.DONE)
      return statuesWithDoneCategory.length === 1
    },
    isStatusCategoryDoneAndLastStatus() {
      return this.workflowStatus?.category === ISSUE_STATUS_CATEGORY.DONE && this.isSingleStatusInDoneCategory
    },
    tooltipTextForStatusRemoveButton() {
      return this.isWorkflowAssociated
        ? this.$t("1534")
        : this.statusHasInitialTransition
          ? this.$t("1533")
          : this.isStatusCategoryDoneAndLastStatus
            ? this.$t("1535")
            : ""
    }
  },
  methods: {
    ...mapActions({
      notify: "shared/notify"
    }),
    ...mapMutations({
      setStatusUpdated: "workflows/setStatusUpdated",
      setStatusError  : "workflows/setStatusError"
    }),
    getTransitionsToDisplay(workflowTransitions) {
      const transitions = []
      for (const transition of workflowTransitions) {
        const transitionFromStatus = transition.transitionLinks.map(transitionLink => {
          return {
            fromStatusId  : transitionLink.fromStatusId,
            fromStatusName: this.statusesMap[transitionLink.fromStatusId] ?
              this.statusesMap[transitionLink.fromStatusId].name : this.$t("948")
          }
        })
        transitions.push({
          transitionId     : transition.id,
          transitionName   : transition.name,
          fromStatus       : transitionFromStatus,
          toStatus         : this.statusesMap[transition.transitionLinks[0].toStatusId].name,
          initialTransition: transition.initialTransition,
          sortingOrder     : transition.initialTransition ? "0" :
            transition.id ? transition.id.toString() : transition.name
        })
      }
      transitions.sort((firstTransition, secondTransition) =>
        firstTransition.sortingOrder.localeCompare(secondTransition.sortingOrder))
      return transitions
    },
    handleCloseSelectedStatus() {
      this.setStatusError({
        workflowId     : this.workflow.id,
        statusNameError: false
      })
      this.$router.push({
        name  : "workflow",
        params: {
          id: this.workflow.id
        }
      })
    },
    handleTransitionClick(transition) {
      this.$router.push({
        name  : "transition",
        params: {
          id: this.workflow.id
        },
        query: {
          status        : this.workflowStatus.name,
          transitionName: transition.name
        }
      })
    },
    handleStatusNameInputOnFocusEvent(onFocus) {
      onFocus()
      this.isStatusNameFocused = true
    },
    revertWorkflowStatusNameChange() {
      this.deleteChangedWorkflowStatusName()
      const copyWorkflows    = JSON.parse(JSON.stringify(this.workflows))
      const updatedWorkflows = copyWorkflows.map(workflow => {
        workflow.statuses = workflow.statuses.map(status => {
          if (status.name === this.selectedStatus) {
            status.name = this.issueStatus.name
          }
          return status
        })
        return workflow
      })
      saveToLocalStorage("workflows", updatedWorkflows)
      this.workflows = updatedWorkflows
    },
    deleteChangedWorkflowStatusName() {
      const copyChangedWorkflows = JSON.parse(JSON.stringify(this.changedWorkflows))
      if (copyChangedWorkflows && copyChangedWorkflows[this.workflow.id]) {
        const changedWorkflow = copyChangedWorkflows[this.workflow.id]

        if (changedWorkflow.status.add) {
          const statusIndex = changedWorkflow.status.add.findIndex(status =>
            status.id === this.workflowStatus.id
          )
          if (statusIndex >= 0) {
            changedWorkflow.status.add[statusIndex].name = this.issueStatus.name
          }
        }

        if (changedWorkflow.status.update) {
          const statusIndex = changedWorkflow.status.update.findIndex(status =>
            status.id === this.workflowStatus.id
          )
          if (statusIndex >= 0) {
            changedWorkflow.status.update.splice(statusIndex, 1)
          }

          if (!changedWorkflow.status.update.length) {
            delete changedWorkflow.status.update
          }
        }

        if (!Object.keys(changedWorkflow.status).length) {
          delete changedWorkflow.status
        }

        if (!Object.keys(changedWorkflow).length) {
          delete copyChangedWorkflows[this.workflow.id]
        }
        saveToLocalStorage("changedWorkflows", copyChangedWorkflows)
        this.changedWorkflows = copyChangedWorkflows
      }
    },
    handleStatusNameInputOnBlurEvent(onBlur) {
      onBlur()
      const isAnExistingStatus   = this.workflow.statuses.find(status =>
        status.name === this.localWorkflowStatus.name)
      this.isStatusNameDuplicate = !!(isAnExistingStatus && this.localWorkflowStatus.name !== this.workflowStatus?.name)

      if (this.localWorkflowStatus.name?.length
        && this.isWorkflowStatusNameChanged
        && !this.isAnExistingStatus && !this.isStatusNameDuplicate
      ) {
        this.verifyAndUpdateWorkflowStatusName()
      }
      this.isStatusNameFocused = false
    },
    handleStatusNameInputOnEnter() {
      this.$refs.text_field_status_name.blur()
    },
    //TODO this needs to be refactored to write it in a better way
    handleRemoveWorkflowStatus() {
      const workflowToUpdate = JSON.parse(JSON.stringify(this.workflow))

      const transitionIdsToBeDeleted   = []
      const transitionNamesToBeDeleted = []

      for (const transition of workflowToUpdate.transitions) {
        const filteredTransitionLinks = transition.transitionLinks.filter(link => {
          // filter out transition links which is not using the status to be deleted
          if (typeof link.fromStatusId === "string") {
            return link.fromStatusId !== this.localWorkflowStatus.name
          }
          if (typeof link.toStatusId === "string") {
            return link.toStatusId !== this.localWorkflowStatus.name
          }
          return link.fromStatusId !== this.localWorkflowStatus.id && link.toStatusId !== this.localWorkflowStatus.id
        })

        if (filteredTransitionLinks.length) {
          if (filteredTransitionLinks.length !== transition.transitionLinks.length) {
            transition.transitionLinks = filteredTransitionLinks
          }
        } else {
          if (transition.id) {
            transitionIdsToBeDeleted.push(transition.id)
          } else {
            transitionNamesToBeDeleted.push(transition.name)
          }
        }
      }
      // update workflow statuses in the workflows local storage
      workflowToUpdate.statuses = workflowToUpdate.statuses.filter(status => {
        if (this.localWorkflowStatus.id) {
          return status.id !== this.localWorkflowStatus.id
        } else {
          return status.name !== this.localWorkflowStatus.name
        }
      })

      // update workflow transitions in the workflows local storage
      workflowToUpdate.transitions = workflowToUpdate.transitions.filter(transition => {
        if (transition.id) {
          return !transitionIdsToBeDeleted.includes(transition.id)
        } else {
          return !transitionNamesToBeDeleted.includes(transition.name)
        }
      })

      const updatedWorkflows = this.workflows.map(workflow => {
        if (workflow.id === this.workflow.id) {
          return workflowToUpdate
        }
        return workflow
      })

      saveToLocalStorage("workflows", updatedWorkflows)
      this.workflows = updatedWorkflows

      const changedWorkflows = getFromLocalStorage("changedWorkflows") || {}
      const changedWorkflow  = changedWorkflows?.[this.workflow.id] || {}

      // update the changedWorkflows local storage
      if (changedWorkflow.status?.add) {
        // Remove the status from add payload if status to be deleted exists
        changedWorkflow.status.add = changedWorkflow.status.add
          .filter(status => status.id !== this.localWorkflowStatus.id || status.name !== this.localWorkflowStatus.name)

        if (!changedWorkflow.status.add.length) {
          delete changedWorkflow.status.add
        }

        if (!Object.keys(changedWorkflow.status).length) {
          delete changedWorkflow.status
        }
      }

      if (changedWorkflow.transition) {
        if (changedWorkflow.transition.add) {
          // filter out the transition links from transition add payload which is using the status to be deleted
          changedWorkflow.transition.add = changedWorkflow.transition.add.filter(transition => {
            transition.transitionLinks = transition.transitionLinks.filter(link => {
              if (this.localWorkflowStatus.id) {
                return link.fromStatusId !== this.localWorkflowStatus.id &&
                link.toStatusId !== this.localWorkflowStatus.id
              } else {
                return link.fromStatusId !== this.localWorkflowStatus.name &&
                link.toStatusId !== this.localWorkflowStatus.name
              }
            })
            if (!transition.transitionLinks.length) {
              return false
            }
            return true
          })

          if (!changedWorkflow.transition.add.length) {
            delete changedWorkflow.transition.add
          }
        }

        if (changedWorkflow.transition.update) {
          // filter out the transition links from transition update payload which is using the status to be deleted
          changedWorkflow.transition.update = changedWorkflow.transition.update.map(transition => {
            if (transition.transitionLinks) {
              transition.transitionLinks = transition.transitionLinks.filter(link => {
                if (this.localWorkflowStatus.id) {
                  return link.fromStatusId !== this.localWorkflowStatus.id &&
                link.toStatusId !== this.localWorkflowStatus.id
                } else {
                  return link.fromStatusId !== this.localWorkflowStatus.name &&
                link.toStatusId !== this.localWorkflowStatus.name
                }
              })
              if (!transition.transitionLinks.length) {
                // return null to filter out the transition after the map
                return null
              }
            }
            if (transitionIdsToBeDeleted.includes(transition.id)) {
              return null
            }
            return transition
          }).filter(transition => transition)
        }

        if (!Object.keys(changedWorkflow.transition).length) {
          delete changedWorkflow.transition
        }
      }

      const statusExistsInWorkflow = !!(this.workflowFromStore.statuses.find(existingStatus =>
        existingStatus.id === this.localWorkflowStatus.id))

      // add status id to delete payload
      if (this.localWorkflowStatus.id && statusExistsInWorkflow) {
        if (changedWorkflow.status?.delete) {
          if (!changedWorkflow.status.delete.includes(this.localWorkflowStatus.id)) {
            changedWorkflow.status.delete.push(this.localWorkflowStatus.id)
          }
        } else {
          changedWorkflow.status = {
            ...changedWorkflow.status,
            delete: [this.localWorkflowStatus.id]
          }
        }
      }

      if (!Object.keys(changedWorkflow).length) {
        delete changedWorkflows[this.workflow.id]
      } else {
        changedWorkflows[this.workflow.id] = changedWorkflow
      }

      saveToLocalStorage("changedWorkflows", changedWorkflows)
      this.changedWorkflows = changedWorkflows

      this.setStatusUpdated(true)

      this.$router.push({
        name  : "workflow",
        params: {
          id: this.workflow.id
        }
      })
    },
    verifyAndUpdateWorkflowStatusName() {
      let previousWorkFlowStatusName = ""
      if (!(this.issueStatus?.name === this.localWorkflowStatus.name)) {
        // workflow map is required to store the previous status name which do not have id (adding new status)
        const updatedWorkflows = this.workflows.map(workflow => {
          workflow.statuses = workflow.statuses.map(status => {
            if (status.name === this.selectedStatus) {
              previousWorkFlowStatusName = status.name
              status.name                = this.localWorkflowStatus.name
            }
            return status
          })
          return workflow
        })
        const changedWorkflows = getFromLocalStorage("changedWorkflows") || {}
        let changedWorkflow    = changedWorkflows[this.workflow.id]
        const updateStatus     = {
          id  : this.localWorkflowStatus.id,
          name: this.localWorkflowStatus.name
        }

        if (changedWorkflow) {
          if (changedWorkflow.status) {
            const hasChangedWorkflowAddProperty    = changedWorkflow.status.add
            const hasChangedWorkflowUpdateProperty = changedWorkflow.status.update
            let localStatusToUpdateIndex

            if (hasChangedWorkflowAddProperty) {
              // trying to update a local status while adding new status
              localStatusToUpdateIndex = changedWorkflow.status.add.findIndex(status =>
                status.name === previousWorkFlowStatusName
              )

              if (localStatusToUpdateIndex >= 0) {
                changedWorkflow.status.add[localStatusToUpdateIndex].name = this.localWorkflowStatus.name
              }
            }

            if (this.localWorkflowStatus.id) {
              if (hasChangedWorkflowUpdateProperty) {
                // trying to update an existing status
                const indexOfExistingStatus = changedWorkflow.status.update.findIndex(status =>
                  status.id === this.localWorkflowStatus.id)
                if (indexOfExistingStatus >= 0) {
                  changedWorkflow.status.update[indexOfExistingStatus].name = this.localWorkflowStatus.name
                } else {
                  // updating status, not present in the changedWorkflow object
                  changedWorkflow.status.update.push(updateStatus)
                }
              } else {
                // when changedWorkflow contains status but not the update key
                changedWorkflow = {
                  ...changedWorkflow,
                  status: {
                    ...changedWorkflow.status,
                    update: [updateStatus]
                  }
                }
              }
            }
          } else {
            // when status property is not present in the changedWorkflow object for current workflow
            changedWorkflow = {
              ...changedWorkflow,
              status: {
                update: [updateStatus]
              }
            }
          }
        } else {
          // when changedWorkflow is empty and we are changing the status name
          changedWorkflow = {
            status: {
              update: [updateStatus]
            }
          }
        }

        changedWorkflows[this.workflow.id] = changedWorkflow
        saveToLocalStorage("changedWorkflows", changedWorkflows)
        this.changedWorkflows = changedWorkflows

        saveToLocalStorage("workflows", updatedWorkflows)
        this.workflows = updatedWorkflows
      } else {
        this.revertWorkflowStatusNameChange()
      }

      this.$router.push({
        name : "workflow-status-side-panel",
        query: {
          status: this.localWorkflowStatus.name
        }
      })
      this.setStatusUpdated(true)
    }
  },
  watch: {
    workflowStatus: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          const isLocalWorkflowStatusNotSet    = !this.localWorkflowStatus
          const isLocalWorkflowSetButDifferent = !isLocalWorkflowStatusNotSet &&
            JSON.stringify(this.localWorkflowStatus) !== JSON.stringify({
              id  : this.workflowStatus.id,
              name: this.workflowStatus.name
            })
          if (isLocalWorkflowStatusNotSet || isLocalWorkflowSetButDifferent) {
            this.localWorkflowStatus = { ...newValue }
          }
        }
      }
    },
    disableUpdateWorkflow: {
      immediate: true,
      handler  : function(newValue) {
        this.setStatusError({
          workflowId     : this.workflow.id,
          statusNameError: newValue
        })
      }
    },
    "$route": {
      handler: function(newValue) {
        if (newValue.query.status !== this.workflowStatus?.name) {
          this.workflows = getFromLocalStorage("workflows")
        }
      }
    }
  }
}