import { TRANSITION_DIRECTIONS } from "@/constants"

export default {
  name: "WorkflowTransition",
  data: () => ({
  }),
  props: {
    pTransition         : Object,
    pTransitionDirection: String,
    pSelectedStatus     : String
  },
  methods: {
    statusChipColor(fromStatus, status) {
      if (this.pTransitionDirection === TRANSITION_DIRECTIONS.INCOMING) {
        return fromStatus ? "grey lighten-2" : "grey darken-4"
      } else if (fromStatus && (status === this.pSelectedStatus || status === this.$t("948"))) {
        return "grey darken-4"
      }
      return "grey lighten-2"
    },
    statusChipTextColor(fromStatus, status) {
      if (this.pTransitionDirection === TRANSITION_DIRECTIONS.INCOMING) {
        return fromStatus ? "grey darken-4" : "white"
      } else if (fromStatus && (status === this.pSelectedStatus || status === this.$t("948"))) {
        return "white"
      }
      return "grey darken-4"
    },
    onTransitionClick() {
      this.$emit("transitionClick", {
        id  : this.pTransition.transitionId,
        name: this.pTransition.transitionName
      })
    }
  }
}